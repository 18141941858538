import React from "react";
import CouponChecker from "./CouponChecker"; // Make sure the path is correct

import logo from "./logo.png";

function App() {
  return (
    <div className="App">
      <h1 style={{ fontWeight: 100, textAlign: "center", color: "white" }}>
        Coupon Checker
      </h1>
      <div
        style={{
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          display: "flex",
        }}
      >
        <img
          src={logo}
          alt="Eventure"
          style={{
            maxHeight: 100,
          }}
        />
      </div>
      <CouponChecker />
    </div>
  );
}

export default App;
