import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useLocation } from "react-router-dom"; // make sure to install react-router-dom if not already installed
import "./index.css"; // Assuming you have a CSS file for styles

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyB1qw3HI5UkCzu1FKkwB6e7iUH2ke9oSHU",
  authDomain: "date-night-exp-marketplace.firebaseapp.com",
  projectId: "date-night-exp-marketplace",
  storageBucket: "date-night-exp-marketplace.appspot.com",
  messagingSenderId: "402182794729",
  appId: "1:402182794729:web:d3d6171baa1f1b7f8fef78",
  measurementId: "G-EVFV4MLP8P",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const db = firebase.firestore();

function CouponChecker() {
  const [couponCode, setCouponCode] = useState("");
  const [businessCode, setBusinessCode] = useState("");
  const [status, setStatus] = useState(""); // New state to manage the status of the coupon
  const [coupon, setCoupon] = useState(null); // New state to store the coupon data
  const location = useLocation();

  useEffect(() => {
    const code = location.pathname.split("/").pop();
    setCouponCode(code);
  }, [location]);

  const handleCheckCoupon = () => {
    db.collection("Coupons")
      .where("couponCode", "==", couponCode)
      .where("businessCode", "==", businessCode)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const couponData = querySnapshot.docs[0].data();
          const currentTime = new Date();
          const expirationTime = new Date(couponData.campaignEndDate.toDate());

          if (currentTime > expirationTime) {
            setStatus("expired");
          } else if (couponData.usedDate) {
            setStatus("used");
          } else {
            setStatus("valid");
            setCoupon(querySnapshot.docs[0]);
          }
        } else {
          setStatus("not_found");
        }
      })
      .catch((error) => {
        console.error("Error checking coupon:", error);
      });
  };

  const handleClaimCoupon = () => {
    coupon.ref
      .update({
        usedDate: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        setStatus("claimed");
      });
  };

  const resetChecker = () => {
    setStatus("");
    setCouponCode("");
    setBusinessCode("");
  };

  return (
    <div className="coupon-checker-container">
      {status === "" && (
        <div>
          <input
            className="input-field"
            type="text"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <input
            className="input-field"
            type="text"
            value={businessCode}
            onChange={(e) => setBusinessCode(e.target.value)}
            placeholder="Business Code"
          />
          <button className="check-button" onClick={handleCheckCoupon}>
            Check Coupon
          </button>
        </div>
      )}

      {status === "valid" && (
        <div>
          <p className="message">Coupon is valid!</p>
          <button
            className="check-button"
            style={{
              backgroundColor: "#017409",
              color: "white",
              border: "none",
            }}
            onClick={handleClaimCoupon}
          >
            CLAIM
          </button>
          <button
            className="check-button"
            style={{ marginTop: 10 }}
            onClick={resetChecker}
          >
            Cancel
          </button>
        </div>
      )}

      {status === "expired" && (
        <div>
          <p className="message">Coupon has expired.</p>
          <button className="check-button" onClick={resetChecker}>
            Back
          </button>
        </div>
      )}

      {status === "used" && (
        <div>
          <p className="message">Coupon has already been used.</p>
          <button className="check-button" onClick={resetChecker}>
            Back
          </button>
        </div>
      )}

      {status === "not_found" && (
        <div>
          <p className="message">Coupon not found.</p>
          <button className="check-button" onClick={resetChecker}>
            Back
          </button>
        </div>
      )}

      {status === "claimed" && (
        <div>
          <p className="message">Coupon successfully claimed!</p>
        </div>
      )}
    </div>
  );
}

export default CouponChecker;
